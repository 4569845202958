import { useEffect, useState } from 'react';
import { PageEditorAPI } from '@crazyegginc/page-editor-api';
import CrazyeggLogo from '@crazyegginc/hatch/dist/images/logo-crazy-egg-solid.svg?react';
import LoadingIconWebp from '@crazyegginc/hatch/dist/images/illustration-loading-balloon.webp';
import LoadingIconPNG from '@crazyegginc/hatch/dist/images/illustration-loading-balloon.png';
import { WebP } from './WebP';

export function Redirect() {
  let [url, setUrl] = useState();

  useEffect(() => {
    let param = new URLSearchParams(location.search).get('url');
    if (!param || !param.startsWith('https://editor.crazyegg.com')) {
      param = 'https://editor.crazyegg.com';
    }

    setUrl(param);
  }, []);

  function onDownload(e) {
    e.preventDefault();
    PageEditorAPI.downloadApp();
  }

  useEffect(() => {
    if (url) {
      location = url.replace('https://', 'crazyegg://');
    }
  }, [url]);

  return (
    <div className="bg-gray-600 w-screen h-screen pt-10 flex flex-col items-center">
      <CrazyeggLogo className="fill-white w-[155px]" />
      <WebP webp={LoadingIconWebp} fallback={LoadingIconPNG} className="w-[155px] mt-[200px] mb-[20px]" />
      <div className="text-white">
        <h2 className="text-4xl font-bold">Launching Crazy Egg App</h2>
        <p className="text-sm mt-2.5">You will be redirected in a few moments.</p>
        <p className="text-sm  mt-2.5">
          Don&apos;t have the app yet?{' '}
          <a onClick={onDownload} href="/" className="text-sm text-blue-700">
            Download Crazy Egg for Desktop
          </a>
        </p>
        <p className="text-sm mt-2.5">
          You can also{' '}
          <a href={url} className="text-sm text-blue-700">
            open this link in your browser.
          </a>
        </p>
      </div>
    </div>
  );
}
