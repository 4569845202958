import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Redirect } from './Redirect';
import { StandaloneEditor } from './StandaloneEditor';

export function Router() {
  const router = getRouter();
  return <RouterProvider router={router} />;
}

function getRouter() {
  return createBrowserRouter([
    {
      path: '/redirect',
      element: <Redirect />,
    },
    {
      path: '*',
      element: <StandaloneEditor />,
    },
  ]);
}
